import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { ALL_BASE_HEADERS } from './config';
import { STORAGE_KEYS, AUTH_STORE, doRefetchToken, PODO_STORE, doRefetchPodoToken, checkLoginState } from 'src/store';
import { tansParams } from './utils';
import { getHeader } from './directus.config';
import { Modal } from 'antd-mobile';

export function getBaseURL(): string {
  return 'https://1lphaand9mega.great-supper.com/';
}

// const baseURL = 'https://podo-test.great-supper.com/podo_new/';

const instance = axios.create({
  baseURL: getBaseURL(),
  timeout: 20000,
  headers: ALL_BASE_HEADERS.JSON,
});

const urlEncode = (data: Record<string, string | number | boolean>): string => {
  const _result: string[] = [];
  for (const key in data) {
    const value = data[key];
    if (value === null) {
      _result.push(key + '=' + '');
    } else if (Array.isArray(value)) {
      value.forEach((_value) => {
        _result.push(key + '=' + _value);
      });
    } else {
      _result.push(key + '=' + value);
    }
  }
  return _result.join('&');
};

instance.interceptors.request.use(
  async (request) => {
    if (!request.url?.includes('podo-api.great-supper.com/api/authentications')) {
      const expiredTime = await AUTH_STORE.getItem(STORAGE_KEYS.AUTH.LOGIN_TIME);
      const state = await checkLoginState();
      if (!expiredTime || !state) {
        Modal.alert({
          content: '操作时间超过1小时,请重新登录',
          onConfirm: () => {
            PODO_STORE.setItem(STORAGE_KEYS.AUTH.LOGIN_TIME, '');
            window.location.replace('/relogin');
          },
        });
      }
    }
    const podoToken = await PODO_STORE.getItem(STORAGE_KEYS.PODO.TOKEN);
    const token = await AUTH_STORE.getItem(STORAGE_KEYS.AUTH.USER_TOKEN);
    // console.log('api token: ', podoToken);
    // console.log('direc token: ', token);
    let headers = {
      Authorization: `${request.url?.includes('https://podo-api.great-supper.com/') ? podoToken : token}`,
    };
    if (request.url?.includes('podo-test.great-supper.com/podo_new')) {
      headers = {
        ...getHeader(),
        ...headers,
      };
    }
    request.headers = {
      ...request.headers,
      ...headers,
    } as AxiosRequestHeaders;
    request.data = {
      ...request.data,
    };

    if (request.method === 'get' && request.params) {
      let url = request.url + '?' + tansParams(request.params);
      url = url.slice(0, -1);
      request.params = {};
      request.url = url;
    }

    if (request.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      request.data = urlEncode(request.data);
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

async function refreshToken(type: 'PODO' | 'DIREC', request: AxiosRequestConfig) {
  if (type === 'DIREC') {
    await doRefetchToken(true);
  }
  if (type === 'PODO') {
    await doRefetchPodoToken(true);
  }
  return await instance.request(request);
}

const specialURLs = [
  'flows/trigger/21e626ac-1afe-460c-b135-97fa4ca667d4', // getStudentListByName, getStudentListById
  'flows/trigger/b02cc853-336a-427c-9d1d-ad83512df363', // get applied student list by student id; getAppliedStudentListByStudentId
  'flows/trigger/ac0f8316-9bd5-430e-a9d5-ba35c14b2eba', // get applied ydr list by user id; getAppliedYDRListByUserId
  // 'flows/trigger/a0bffdc4-313e-411f-8133-841bc12c8555', // update tutor apply
  'flows/trigger/fc938c66-d69d-4c04-985c-6b04808127eb', // 申请更换ydr流程，ydr申请个教
  'flows/trigger/6f8b6c03-2ddb-46c8-8eb8-da6a41e0dba5', // update student class
  'flows/trigger/45147ced-157b-499c-8b1d-a4c77dca68cf', // update fruit's name
  'flows/trigger/0090309b-c365-4e37-acab-8e6e7c5ad7d9', // update class apply
  '/auth/login',
  'https://podo-api.great-supper.com/',
];

instance.interceptors.response.use(
  (response) => {
    // 统一处理响应数据，例如返回数据中的 code、message 字段
    const { config, status } = response;
    const { code, message, data } = response.data;
    const msg_status = response.data.status;
    const isSpecialURL = specialURLs.find((item) => config.url?.includes(item));
    if ([200, 201].includes(status) && isSpecialURL) {
      return response.data;
    }
    console.log('response.data: ', response.data);
    console.log('status: ', status);
    console.log('code: ', code);
    console.log('msg_status: ', msg_status);
    // code === 'FORBIDDEN'
    if (code === 401 || status === 401 || status === 403) {
      if (response.config.url && response.config.url.includes('https://podo-api.great-supper.com/')) {
        return refreshToken('PODO', response.config);
      } else {
        return refreshToken('DIREC', response.config);
      }
    }
    if (code === 200) {
      return data || response.data;
    } else {
      Promise.reject(new Error(message || '请求失败'));
    }
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.config.url.includes('https://podo-api.great-supper.com/')
    ) {
      return refreshToken('PODO', error.config);
    } else if (error.code === 'ECONNABORTED') {
      error.response.config.url.includes('https://podo-api.great-supper.com/')
        ? refreshToken('PODO', error.config)
        : refreshToken('DIREC', error.config);
      // 处理超时错误
    } else {
      return Promise.reject(error);
    }
  },
);

export default instance;
